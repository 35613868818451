'use strict';

angular.module('cpformplastApp.orders')
  .config(function($stateProvider) {
    $stateProvider
      .state('orders/order-management/order-creation', {
        url: '/orders/order-management/order-creation/:orderId',
        templateUrl: 'app/orders/order-management/order-creation/order-creation.html',
        controller: 'OrderCreationController',
        controllerAs: 'orderCreationController',
        authenticate: ['admin','administration_dept','management_dept','logistic_dept','receptionist']
      })
      .state('orders/order-management', {
        url: '/orders/order-management',
        templateUrl: 'app/orders/order-management/order-management.html',
        controller: 'OrderManagementController',
        controllerAs: 'orderManagementController',
        authenticate: ['admin','administration_dept','management_dept','logistic_dept','receptionist']
      })
      .state('orders/order-history', {
        url: '/orders/order-history',
        templateUrl: 'app/orders/order-history/order-history.html',
        controller: 'OrderHistoryController',
        controllerAs: 'orderHistoryController',
        authenticate: ['admin','administration_dept','management_dept','logistic_dept','receptionist']
      });
  });
